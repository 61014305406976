.ipr {
  max-width: 55%;

  &__header {
    text-align: left;
  }

  &__input {
    margin: 0 10px !important;
    width: 40%;
  }
}

.teeth {
  padding-top: 70px;
  width: calc(100% / 12);
  position: relative;
  display: flex;
  justify-content: center;
}

.tooth {
  width: 100%;
  display: flex;
  justify-content: center;

  .middle-info {
    position: absolute;
    font-size: 8px;
    right: -75%;
    top: 20px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center;

    &::after {
      content: "";
      position: absolute;
      bottom: -1.5vw;
      border-width: 1.5vw 0.4vw 0 0.4vw;
      border-style: solid;
      border-color: rgb(193, 88, 220) transparent transparent transparent;
    }
  }

  &__arrow-left {
    display: none;
    position: absolute;
    top: 44px;
    left: -15px;
  }

  &__arrow-right {
    display: none;
    position: absolute;
    top: 44px;
    right: -15px;
  }

  &__left-side {
    display: none;

    &__step {
      position: absolute;
      top: 52px;
      left: -5px;
      max-width: 31px;
      font-size: 9px;
    }

    &__mm {
      position: absolute;
      top: 31px;
      left: -5px;
      font-size: 10px;
    }
  }

  &__right-side {
    display: none;

    &__mm {
      position: absolute;
      top: 31px;
      right: -4px;
      font-size: 10px;
    }

    &__step {
      position: absolute;
      text-align: right;
      top: 52px;
      right: -5px;
      max-width: 31px;
      font-size: 9px;
    }
  }

  &__container {
    display: flex;
  }

  &__top {
    display: flex;
    width: calc(50% - ((50% / 12) - 6%));
    justify-content: space-between;
  }

  &__number {
    user-select: none;
    position: absolute;
    top: 65%;
    color: #e0e0e0;
  }

  &__icon {
    user-select: none;
    width: 70%;

    &.active {
      filter: invert(51%) sepia(98%) saturate(2618%) hue-rotate(249deg) brightness(89%) contrast(93%);
    }
  }
}

.fa-long-arrow-alt-up {
  width: 50px;
  height: 50px;
}

.iprIframe {
  width: 50%;
  height: 700px;
  // align-self: center;
}

@media screen and (min-width: 1367px) {
  .tooth .middle-info {
    font-size: 10px;
    right: -45%;
  }
}

@media screen and (min-width: 1921px) {
  .tooth .middle-info {
    right: -50%;
    font-size: 11px;
  }
}

@media screen and (min-width: 2049px) {
  .tooth .middle-info {
    right: -25%;
    font-size: 13px;
  }
}
