.message_me {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: #002d5a;
  outline: none;
  cursor: pointer;
  position: fixed;
  right: 25px;
  bottom: 25px;
  &:hover {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
  }
  svg {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 14px;
    left: 11px;
    path {
      fill: #fff;
    }
  }
}
.toolbar {
  justify-content: flex-start !important;
}
.chat-button {
  //position: absolute;
  //right: 10px;
  //bottom: 10px;
  background: #002d5a;
  height: 50px;
  font-size: 16px;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: 0;
  box-shadow: 1px 1px 4px 1px grey;
  cursor: pointer;
}
.form-container {
  height: 400px;
  border: 1px solid #002d5a;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  position: fixed;
  bottom: 72px;
  right: 25px;
  background-color: #f2f5f8;
}
.form-container h1 {
  padding: 10px 20px;
  background-color: #002d5a;
  font-size: 20px;
  color: white;
  margin: 0 0 10px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.form {
  padding: 30px;
}
.input {
  display: block;
  width: 100%;
  margin: 20px auto;
  height: 50px;
  font-size: 20px;
  padding-left: 5px;
  padding-right: 5px;
}
.submit {
  height: 40px;
  padding: 0 20px;
  background-color: #002d5a;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
}
.ChatWidget {
  position: fixed;
  right: 36px;
  bottom: 150px;
  max-width: 500px;
  z-index: 10000;
  width: 100%;
  &.off {
    width: 150px;
  }
}

.form {
  padding: 30px;
}
.input {
  display: block;
  width: 100%;
  margin: 20px auto;
  height: 50px;
  font-size: 20px;
}
.submit {
  border: none;
  height: 40px;
  background-color: #002d5a;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  position: relative;
  padding-left: 35px;
  &:hover {
    transition: all 0.3s ease-in-out;
    filter: brightness(90%);
  }
  svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 9px;
    left: 10px;
    path {
      fill: #fff;
    }
  }
}
.header {
  padding: 5px;
  height: 25px;
  color: white;
  margin-top: 0;
  text-align: left;
  border-bottom: 1px solid #002d5a;
  background-color: #002d5a;
  margin-bottom: 0;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}
.message-list {
  list-style-type: none;
  padding: 0 20px 40px;
  padding: 0 20px 0;
  overflow: scroll;
  max-height: 66vh;
  min-height: 66vh;
  margin-bottom: 15px;
  margin-bottom: 55px;
  background-color: #f2f5f8;
}
.message-sender {
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0 0;
  line-height: 20px;
}
.message-day {
  margin-left: 10px;
  font-size: 12px;
}
.message-text {
  margin-top: 5px;
  background-color: #002d5a;
  color: white;
  padding: 5px 10px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  position: relative;
  display: inline-block;
  word-break: break-word;

  a {
    color: white;
  }
}
.message-top {
  display: flex;
  align-items: flex-end;
}
.message-input {
  width: 85%;
  height: 50px;
  border: 1px solid #e0e0e0;
  font-size: 16px;
  padding: 10px;
  outline: none;
  resize: none;
}
.message-submit {
  width: 15%;
  background-color: #002d5a;
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  outline: none;
  border: none;
  &:hover {
    transition: all 0.3s ease-in-out;
    filter: brightness(90%);
  }
  svg {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 23px;
    left: 12px;

    path {
      fill: #fff;
    }
  }
}
.input-field {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
}

.chat-app {
  position: fixed;
  right: 25px;
  bottom: 25px;
  min-width: 350px;
  max-width: 350px;
  min-height: 75vh;
  max-height: 75vh;

  .chat_close {
    position: absolute;
    top: 5px;
    right: 15px;
    color: white;
    border: none;
    background-color: transparent;
    font-size: 25px;
    cursor: pointer;
  }
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.empty {
  position: absolute;
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -50px;
}

iframe {
  background-color: #fff !important;
  object {
    embed {
      max-width: 100%;
    }
  }
}
