@font-face {
  font-family: AvenirNext;
  src: url(/static/media/AvenirNext.4d8fdeb2.ttf) format("truetype");
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#addresses button span {
  color: #26c2cc !important;
}

.message_me {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: #002d5a;
  outline: none;
  cursor: pointer;
  position: fixed;
  right: 25px;
  bottom: 25px; }
  .message_me:hover {
    -webkit-animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
            animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); }
  .message_me svg {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 14px;
    left: 11px; }
    .message_me svg path {
      fill: #fff; }

.toolbar {
  justify-content: flex-start !important; }

.chat-button {
  background: #002d5a;
  height: 50px;
  font-size: 16px;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: 0;
  box-shadow: 1px 1px 4px 1px grey;
  cursor: pointer; }

.form-container {
  height: 400px;
  border: 1px solid #002d5a;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  position: fixed;
  bottom: 72px;
  right: 25px;
  background-color: #f2f5f8; }

.form-container h1 {
  padding: 10px 20px;
  background-color: #002d5a;
  font-size: 20px;
  color: white;
  margin: 0 0 10px;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px; }

.form {
  padding: 30px; }

.input {
  display: block;
  width: 100%;
  margin: 20px auto;
  height: 50px;
  font-size: 20px;
  padding-left: 5px;
  padding-right: 5px; }

.submit {
  height: 40px;
  padding: 0 20px;
  background-color: #002d5a;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase; }

.ChatWidget {
  position: fixed;
  right: 36px;
  bottom: 150px;
  max-width: 500px;
  z-index: 10000;
  width: 100%; }
  .ChatWidget.off {
    width: 150px; }

.form {
  padding: 30px; }

.input {
  display: block;
  width: 100%;
  margin: 20px auto;
  height: 50px;
  font-size: 20px; }

.submit {
  border: none;
  height: 40px;
  background-color: #002d5a;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  font-weight: 600;
  position: relative;
  padding-left: 35px; }
  .submit:hover {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-filter: brightness(90%);
            filter: brightness(90%); }
  .submit svg {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 9px;
    left: 10px; }
    .submit svg path {
      fill: #fff; }

.header {
  padding: 5px;
  height: 25px;
  color: white;
  margin-top: 0;
  text-align: left;
  border-bottom: 1px solid #002d5a;
  background-color: #002d5a;
  margin-bottom: 0;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px; }

.message-list {
  list-style-type: none;
  padding: 0 20px 40px;
  padding: 0 20px 0;
  overflow: scroll;
  max-height: 66vh;
  min-height: 66vh;
  margin-bottom: 15px;
  margin-bottom: 55px;
  background-color: #f2f5f8; }

.message-sender {
  font-weight: bold;
  font-size: 18px;
  margin: 10px 0 0;
  line-height: 20px; }

.message-day {
  margin-left: 10px;
  font-size: 12px; }

.message-text {
  margin-top: 5px;
  background-color: #002d5a;
  color: white;
  padding: 5px 10px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  position: relative;
  display: inline-block;
  word-break: break-word; }
  .message-text a {
    color: white; }

.message-top {
  display: flex;
  align-items: flex-end; }

.message-input {
  width: 85%;
  height: 50px;
  border: 1px solid #e0e0e0;
  font-size: 16px;
  padding: 10px;
  outline: none;
  resize: none; }

.message-submit {
  width: 15%;
  background-color: #002d5a;
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  outline: none;
  border: none; }
  .message-submit:hover {
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    -webkit-filter: brightness(90%);
            filter: brightness(90%); }
  .message-submit svg {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 23px;
    left: 12px; }
    .message-submit svg path {
      fill: #fff; }

.input-field {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex; }

.chat-app {
  position: fixed;
  right: 25px;
  bottom: 25px;
  min-width: 350px;
  max-width: 350px;
  min-height: 75vh;
  max-height: 75vh; }
  .chat-app .chat_close {
    position: absolute;
    top: 5px;
    right: 15px;
    color: white;
    border: none;
    background-color: transparent;
    font-size: 25px;
    cursor: pointer; }

@-webkit-keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }

@keyframes shake {
  10%,
  90% {
    -webkit-transform: translate3d(-1px, 0, 0);
            transform: translate3d(-1px, 0, 0); }
  20%,
  80% {
    -webkit-transform: translate3d(2px, 0, 0);
            transform: translate3d(2px, 0, 0); }
  30%,
  50%,
  70% {
    -webkit-transform: translate3d(-4px, 0, 0);
            transform: translate3d(-4px, 0, 0); }
  40%,
  60% {
    -webkit-transform: translate3d(4px, 0, 0);
            transform: translate3d(4px, 0, 0); } }

.empty {
  position: absolute;
  left: 50%;
  margin-left: -50px;
  top: 50%;
  margin-top: -50px; }

iframe {
  background-color: #fff !important; }
  iframe object embed {
    max-width: 100%; }

.ipr {
  max-width: 55%; }
  .ipr__header {
    text-align: left; }
  .ipr__input {
    margin: 0 10px !important;
    width: 40%; }

.teeth {
  padding-top: 70px;
  width: calc(100% / 12);
  position: relative;
  display: flex;
  justify-content: center; }

.tooth {
  width: 100%;
  display: flex;
  justify-content: center; }
  .tooth .middle-info {
    position: absolute;
    font-size: 8px;
    right: -75%;
    top: 20px;
    white-space: nowrap;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .tooth .middle-info::after {
      content: "";
      position: absolute;
      bottom: -1.5vw;
      border-width: 1.5vw 0.4vw 0 0.4vw;
      border-style: solid;
      border-color: #c158dc transparent transparent transparent; }
  .tooth__arrow-left {
    display: none;
    position: absolute;
    top: 44px;
    left: -15px; }
  .tooth__arrow-right {
    display: none;
    position: absolute;
    top: 44px;
    right: -15px; }
  .tooth__left-side {
    display: none; }
    .tooth__left-side__step {
      position: absolute;
      top: 52px;
      left: -5px;
      max-width: 31px;
      font-size: 9px; }
    .tooth__left-side__mm {
      position: absolute;
      top: 31px;
      left: -5px;
      font-size: 10px; }
  .tooth__right-side {
    display: none; }
    .tooth__right-side__mm {
      position: absolute;
      top: 31px;
      right: -4px;
      font-size: 10px; }
    .tooth__right-side__step {
      position: absolute;
      text-align: right;
      top: 52px;
      right: -5px;
      max-width: 31px;
      font-size: 9px; }
  .tooth__container {
    display: flex; }
  .tooth__top {
    display: flex;
    width: calc(50% - ((50% / 12) - 6%));
    justify-content: space-between; }
  .tooth__number {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    position: absolute;
    top: 65%;
    color: #e0e0e0; }
  .tooth__icon {
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 70%; }
    .tooth__icon.active {
      -webkit-filter: invert(51%) sepia(98%) saturate(2618%) hue-rotate(249deg) brightness(89%) contrast(93%);
              filter: invert(51%) sepia(98%) saturate(2618%) hue-rotate(249deg) brightness(89%) contrast(93%); }

.fa-long-arrow-alt-up {
  width: 50px;
  height: 50px; }

.iprIframe {
  width: 50%;
  height: 700px; }

@media screen and (min-width: 1367px) {
  .tooth .middle-info {
    font-size: 10px;
    right: -45%; } }

@media screen and (min-width: 1921px) {
  .tooth .middle-info {
    right: -50%;
    font-size: 11px; } }

@media screen and (min-width: 2049px) {
  .tooth .middle-info {
    right: -25%;
    font-size: 13px; } }

